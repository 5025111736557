






























































































































import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/common/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import TokenCard from "@/components/partials/TokenCard.vue";
import InputField from "@/components/common/InputField.vue";

import {Component, Vue, Watch} from "vue-property-decorator";
import BigNumber from "bignumber.js";
import {ethers} from "ethers";
import {ChainId} from "@uniswap/sdk";
import {tickToPrice} from "@uniswap/v3-sdk";
import {Price, Token} from "@uniswap/sdk-core";
import {abi as IUniswapV3PoolABI} from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import {abi as SWAP_ROUTER_ABI} from "@uniswap/v3-periphery/artifacts/contracts/SwapRouter.sol/SwapRouter.json";
import ERC20_ABI from "@/util/ERC20TokenContract.json";

import {ERC20WalletToken} from "@/interfaces/entities/ERC20WalletToken";
import {PreviousSwap} from "@/interfaces/entities/PreviousSwap";
import {State} from "@/interfaces/State.ts";
import {getPools} from "@/util/PoolInfo.ts";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnect from "@walletconnect/client";
import {createAlchemyWeb3} from "@alch/alchemy-web3";
import {approve} from "@/util/approve";



const minABI = [
    // balanceOf
    {
        "constant": true,
        "inputs": [{"name": "_owner", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"name": "balance", "type": "uint256"}],
        "type": "function"
    },
    // decimals
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [{"name": "", "type": "uint8"}],
        "type": "function"
    }
];

const allowanceABI = [
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }
];

@Component({
    components: {InputField, TDSButton, TDSModal, TDSSpinner, TokenCard}
})
export default class SwapCoins extends Vue {
    private isWalletConnected: boolean = false;
    private networkVersion: number = 1;
    private accounts: string[] = [];
    private tokens: any = [];
    private web3: any;
    private poolContract!: any;
    private tokensInUserWallet: ERC20WalletToken[] = [];
    private selectedTokenFrom: string = "";
    private selectedTokenTo: string = "";
    private swapRouterAddress: string = "0xE592427A0AEce92De3Edee1F18E0157C05861564";

    private midPrice: BigNumber = new BigNumber(1);
    private midPriceInverted: BigNumber = new BigNumber(1);
    private toAmount: BigNumber = new BigNumber(0);
    private fromAmount: BigNumber = new BigNumber(0);
    private amountToSend: BigNumber = new BigNumber(0);
    private amountToRecieve: BigNumber = new BigNumber(0);
    private decimalPointFrom: number | undefined = 2;
    private decimalPointTo: number | undefined = 2;

    private slippage: number = 0;
    private deadline: number = 0;
    private gasPrice: string = "low";

    private minAmount: BigNumber = new BigNumber(0);
    private priceImpact: string = "";
    private fee: BigNumber = new BigNumber("0.03");
    private TokenA!: Token;
    private TokenB!: Token;
    private busyCounter: number = 0;
    private hasLiquidity: boolean = true;
    private tokenAllowance: boolean = true;
    private busyMessage: string = "Loading...";
    private mobileWalletConnector: WalletConnect = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal
    });
    private previousSwaps: PreviousSwap[] = [];
    private transactionHash: string = "";
    // @ts-ignore
    private delay: any = (ms: number) => new Promise((res: TimeHandler) => setTimeout(res, ms));

    // private tcUrl: string = "https://www.dentwireless.com/tc";

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get readyToSwap(): boolean {
        const notEmpty = Boolean((this.amountToSend.toNumber() > 0 && this.toAmount.toNumber() > 0) || (this.amountToRecieve.toNumber() > 0 && this.fromAmount.toNumber() > 0));
        const tokensSelected = Boolean(this.selectedTokenFrom && this.selectedTokenTo && (this.selectedTokenFrom !== this.selectedTokenTo));
        return !this.isBusy && this.hasLiquidity && notEmpty && tokensSelected;
    }

    get isBusy() {
        return this.busyCounter > 0;
    }

    get chainId() {
        switch (process.env.VUE_APP_ETH_NETWORK) {
            case "ropsten":
                return ChainId.ROPSTEN;
            case "mainnet":
                return ChainId.MAINNET;
            default:
                throw new Error("Unknown ethereum network in 'process.env.VUE_APP_ETH_NETWORK': " + process.env.VUE_APP_ETH_NETWORK);
        }
    }

    created() {
        this.init();
    }

    async fromAmountChanged(value: BigNumber) {
        this.amountToSend = value;
        this.toAmount = this.midPrice.multipliedBy(value);
    }

    async toAmountChanged(value: BigNumber) {
        this.amountToRecieve = value;
        this.fromAmount = this.midPriceInverted.multipliedBy(value);
    }

    @Watch("selectedTokenTo")
    toTokenChanged() {
        this.toAmountChanged(new BigNumber("0"));
        this.fromAmountChanged(new BigNumber("0"));
        const TokenData = this.getTokenData(this.selectedTokenTo);
        this.TokenB = new Token(
            this.chainId,
            TokenData.address,
            Number(TokenData.decimals),
            TokenData.symbol,
            TokenData.name
        );
        this.createPool();
    }

    @Watch("selectedTokenFrom")
    async fromTokenChanged() {
        this.fromAmountChanged(new BigNumber("0"));
        this.toAmountChanged(new BigNumber("0"));
        const TokenData = this.getTokenData(this.selectedTokenFrom);
        this.TokenA = new Token(
            this.chainId,
            TokenData.address,
            Number(TokenData.decimals),
            TokenData.symbol,
            TokenData.name
        );
        if (this.TokenA.symbol !== "ETH") {
            if(await this.getAllowance(this.TokenA.address)) {
                this.tokenAllowance = true;
            } else {
                this.tokenAllowance = false;
            }
        } else {
            this.tokenAllowance = true;
        }
        this.createPool();
    }

    async init() {
        this.busyCounter++;
        const result: { default: Token[] } = await import("@/util/tokens/" + process.env.VUE_APP_ETH_NETWORK + ".json");
        this.tokens = result.default;
        // (window as any).web3 = new Web3((window as any).ethereum);
        // this.accounts = await (window as any).web3.eth.getAccounts();
        // if (this.accounts && this.accounts.length > 0) await this.connectWallet();
        try {
            this.setWeb3Provider();
            if (localStorage.getItem("PREVIOUS_SWAPS") !== null) {
                this.previousSwaps = JSON.parse(localStorage.getItem("PREVIOUS_SWAPS") || "{}");
                // check if any swap is still pending
                for (const swap of this.previousSwaps) {
                    if(swap.status === "pending"){
                        let status = false;
                        while(status !== true) {
                            await this.delay(3000); // wait 3s between API calls
                            let isCompleted = false;
                            await this.web3.eth.getTransactionReceipt(swap.txhash, function (e: any, data: any) {
                                if (e !== null) {
                                    isCompleted = false;
                                } else {
                                    isCompleted = true;
                                }
                            });
                            if (isCompleted) {
                                status = true;
                            }
                        }
                    }
                }
            }

            if(!this.isMobile) {
                this.accounts = await (window as any).web3.eth.getAccounts();  //  get user's account address
                this.networkVersion = parseInt((window as any).ethereum.networkVersion) || 1;  // get the network the user is using

            } else {
                this.accounts = this.mobileWalletConnector.accounts;
                this.networkVersion = this.mobileWalletConnector.chainId;
            }
            await this.getTokenBalance(this.accounts[0]); // wait until user's balance is loaded
        } catch(e) {
            console.error("[SwapCoins] Error: ", e);
            this.$toastr.error("An unexpected error occurred. Please check your MetaMask connection and reload the page.");
        }

        this.busyCounter--;
    }

    private getTokenData(tokenSymbol: string) {
        return this.tokens.find((token: any) => token.symbol === tokenSymbol);
    }

    private async createPool() {
        if (!this.TokenA || !this.TokenB || this.TokenA.symbol === this.TokenB.symbol) {
            return;
        }
        this.busyCounter++;

        const [{address: poolAddress }] = getPools();
        let provider;
        if (process.env.VUE_APP_ETH_NETWORK === "ropsten") {
            provider = new ethers.providers.JsonRpcProvider("https://eth-ropsten.alchemyapi.io/v2/9Mt2eQoo503VIRC0hzmg7VkF0ze2swDy");
        } else if (process.env.VUE_APP_ETH_NETWORK === "mainnet") {
            provider = new ethers.providers.JsonRpcProvider("https://eth-mainnet.alchemyapi.io/v2/k_aHLJUvV4hyOdbXGFmmplO6m4iLIxIL");
        } else {
            throw new Error("Unknown ethereum network in 'process.env.VUE_APP_ETH_NETWORK': " + process.env.VUE_APP_ETH_NETWORK);
        }

        this.poolContract = new ethers.Contract(
            poolAddress,
            IUniswapV3PoolABI,
            provider
        );

        const state = await this.getPoolState();
        /*if(state.liquidity.isZero()){
            this.hasLiquidity = false;
            this.$toastr.error("Selected pool has no liquidity.");
        }*/
        const price: Price<Token, Token> = tickToPrice(this.TokenA, this.TokenB, state.tick);
        this.midPrice = new BigNumber(price.toFixed(this.TokenB.decimals));
        this.midPriceInverted = new BigNumber(price.invert().toFixed(this.TokenA.decimals));
        console.log("[SwapCoins] Got midPrice: ", this.midPrice.toFixed(this.TokenB.decimals));
        console.log("[SwapCoins] Got midPriceInverted: ", this.midPriceInverted.toFixed(this.TokenA.decimals));

        this.busyCounter--;
    }

    // loop through calling all smart contacts to find the user's
    // balance of the respected token
    private async getTokenBalance(account: string) {
        this.tokensInUserWallet = [];
        for (const token of this.tokens) {

            // handle errors that could rise from
            // calling the smart contract method
            console.log("[SwapCoins] Trying to get amount of: " + token.name);

            const contract = new this.web3.eth.Contract(minABI, token.address);
            const balance = await contract.methods.balanceOf(account).call();
            const balanceBigNumber = new BigNumber(balance + "e-" + token.decimals);

            // check if balance is equal to zero
            if (token.symbol !== "ETH") {
                if (!balanceBigNumber.isEqualTo(new BigNumber(0))) {
                    const walletToken = {
                        name: token.name,
                        symbol: token.symbol,
                        amount: balanceBigNumber,
                        decimals: token.decimals
                    };
                    this.tokensInUserWallet.push(walletToken);
                } else {
                    console.log("[SwapCoins] User doesn't have any " + token.name + "!");
                }
            } else {
                const balanceETH = await this.web3.eth.getBalance(this.accounts[0]);
                const balanceBigNumber = new BigNumber(balanceETH + "e-" + token.decimals);
                const eth = {
                    name: "Ether",
                    symbol: "ETH",
                    amount: balanceBigNumber,
                    decimals: token.decimals
                };
                this.tokensInUserWallet.push(eth);
            }
        }
    }

    private async handleSwap() {
        this.busyCounter++;
        const routerContract = new this.web3.eth.Contract(SWAP_ROUTER_ABI, this.swapRouterAddress);
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time
        let qty = this.amountToSend.multipliedBy(Math.pow(10, this.TokenA.decimals)).toString();

        if (this.TokenA.symbol == "ETH") {
            qty = this.web3.utils.toWei(this.amountToSend.toString(), "ether");
        }
        const params = {
            tokenIn: this.TokenA.address,
            tokenOut: this.TokenB.address,
            fee: 10000,
            recipient: this.accounts[0],
            deadline: deadline,
            amountIn: qty,
            amountOutMinimum: 0,
            sqrtPriceLimitX96: 0
        };

        try {
            if(!this.isMobile){
                const encodedTx = await routerContract.methods.exactInputSingle(params).send({
                    value: qty,
                    gas: 200000,
                    gasPrice: Number(this.estimateGasPrice()),
                    from: this.accounts[0],
                    to: this.swapRouterAddress
                })
                    .once("transactionHash", (hash: any) => {
                        this.previousSwaps.push({txhash: hash, status: "pending"});
                        const parsed = JSON.stringify(this.previousSwaps);
                        localStorage.setItem("PREVIOUS_SWAPS", parsed);
                        console.log("[SwapCoins] TX: ", hash);
                    })
                    .then((receipt: any) => {
                        // will be fired once the receipt is mined
                        const previousSwaps = JSON.parse(localStorage.getItem("PREVIOUS_SWAPS") || "{}");
                        for (const swap of previousSwaps) {
                            if(swap.txhash === receipt.transactionHash){
                                swap.status = "done";
                            }
                        }
                        localStorage.setItem("PREVIOUS_SWAPS", JSON.stringify(previousSwaps));
                        console.log("[SwapCoins] Receipt: ", receipt);
                        if(String(receipt.status) == "true") {
                            this.$toastr.success("Coin swap was successful.");
                        } else {
                            this.$toastr.error("Transaction failed.");
                        }
                    });
                await this.getTokenBalance(this.accounts[0]);
            } else {
                const data =  routerContract.methods.exactInputSingle(params).encodeABI();
                const tx = await this.mobileWalletConnector.sendTransaction({value: qty, data: data, from: this.accounts[0], to: this.swapRouterAddress, gas: 200000, gasPrice: Number(this.estimateGasPrice)});
                this.$toastr.success("Coin swap was successful.");
            }
        } catch (e) {
            this.$toastr.error("Transaction failed or was cancelled.");
            console.error("[SwapCoins] TX failed: ", e);
        }

        this.busyCounter--;
    }
    private async handleApproval(){
        this.busyCounter++;
        try {
            if(!this.isMobile){
                const encodedTx = await approve(this.accounts[0], this.swapRouterAddress, this.TokenA.address);
                console.log("[SwapCoins] ApprovalTX: ", encodedTx);
                if(String(encodedTx.status) == "true") {
                    this.$toastr.success("Approval was successful.");
                    this.tokenAllowance = true;
                }
                else {
                    this.$toastr.error("Transaction failed.");
                    this.tokenAllowance = false;
                }
            } else {
                const tokenContract = new this.web3.eth.Contract(ERC20_ABI, this.TokenA.address);
                const data =  tokenContract.methods.approve(this.swapRouterAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").encodeABI();
                const tx = await this.mobileWalletConnector.sendTransaction({data: data, from: this.accounts[0], to: this.TokenA.address, gas: 100000, gasPrice: Number(this.estimateGasPrice)});
                this.$toastr.success("Approval was successful.");

                this.tokenAllowance = true;
            }
        } catch (e) {
            this.$toastr.error("Approval failed or was cancelled.");
            console.error("[SwapCoins] TX failed: ", e);
            this.tokenAllowance = false;
        }
        this.busyCounter--;
    }

    // private getMinReceived() {
    //     return new BigNumber(this.minAmount.multipliedBy(this.fromAmount)).toFixed();
    // }
    //
    // private getLiquidityFee() {
    //     return new BigNumber(this.fee.multipliedBy(this.fromAmount)).toFixed();
    // }
    //
    // private async getPoolImmutables() {
    //     const immutables: Immutables = {
    //         factory: await this.poolContract.factory(),
    //         token0: await this.poolContract.token0(),
    //         token1: await this.poolContract.token1(),
    //         fee: await this.poolContract.fee(),
    //         tickSpacing: await this.poolContract.tickSpacing(),
    //         maxLiquidityPerTick: await this.poolContract.maxLiquidityPerTick()
    //     };
    //     return immutables;
    // }

    private async getPoolState() {
        const slot = await this.poolContract.slot0();
        const PoolState: State = {
            liquidity: await this.poolContract.liquidity(),
            sqrtPriceX96: slot[0],
            tick: slot[1],
            observationIndex: slot[2],
            observationCardinality: slot[3],
            observationCardinalityNext: slot[4],
            feeProtocol: slot[5],
            unlocked: slot[6]
        };
        return PoolState;
    }
    private setWeb3Provider(){
        if (process.env.VUE_APP_ETH_NETWORK === "ropsten") {
            this.web3 = createAlchemyWeb3("https://eth-ropsten.alchemyapi.io/v2/9Mt2eQoo503VIRC0hzmg7VkF0ze2swDy");
        } else if (process.env.VUE_APP_ETH_NETWORK === "mainnet") {
            this.web3 = createAlchemyWeb3("https://eth-mainnet.alchemyapi.io/v2/k_aHLJUvV4hyOdbXGFmmplO6m4iLIxIL");
        } else {
            throw new Error("Unknown ethereum network in 'process.env.VUE_APP_ETH_NETWORK': " + process.env.VUE_APP_ETH_NETWORK);
        }
    }
    private async estimateGasPrice() {
        // default value of 50 gwei in case call fails
        let gasPriceEstimate = "50";
        await this.web3.eth.getGasPrice().then((gasPrice: string) => {
            const gas = new BigNumber(gasPrice);
            gasPriceEstimate = gas.dividedToIntegerBy(Math.pow(10, 9)).toString();
        });
        return gasPriceEstimate;
    }
    private async getAllowance(contractAdresss: string) {
        const contract = new this.web3.eth.Contract(allowanceABI, contractAdresss);
        const balance = await contract.methods.allowance(this.accounts[0], this.swapRouterAddress).call();
        const balanceBigNumber = new BigNumber(balance);
        return !balanceBigNumber.isZero();
    }
}
